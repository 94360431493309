<template>
  <div class="detail">
    <img class="head_img" src="./image/header.png" alt="" />
    <div class="m1">
      <div class="t1">
        <img :src="haishi.hstp" alt="" />
        <span><i class="xhgpin iconfont"></i>{{ haishi.gj }}</span>
        <span><i class="xhgstar iconfont"></i>{{ haishi.hd_ztdf }}</span>
      </div>
      <div class="t2">
        <p>{{ haishi.name }}</p>
        <span>{{ haishi.hd_hdsf }}</span>
        <span>活跃</span>
      </div>
      <div class="t3">
        {{haishi.school.zwmc|-}} | {{haishi.major|-}} |
        {{haishi.school.education|-}}
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="m2 yycj" v-if="haishi.hd_yycj.length > 0">
      <div class="title">
        <i class="iconfont xhgtag"></i>
        应用场景
      </div>
      <div class="items">
        <div class="item" v-for="(item, key) in haishi.hd_yycj" :key="key">
          {{ item }}
        </div>
      </div>
      <!-- <img v-else src="./image/null.png" alt=""> -->
    </div>
    <!-- 常规 -->
    <div class="m2" v-if="haishi.cgkc.length > 0">
      <div class="title">
        <i class="iconfont xhgtag"></i>
        常规
      </div>
      <div class="items">
        <div class="item" v-for="(item, key) in haishi.cgkc" :key="key">
          {{ item }}
        </div>
      </div>
      <!-- <img v-else src="./image/null.png" alt=""> -->
    </div>
    <!-- AP -->
    <div class="m2" v-if="haishi.ap.length > 0">
      <div class="title">
        <i class="iconfont xhgtag"></i>
        AP
      </div>
      <div class="items">
        <div class="item" v-for="(item, key) in haishi.ap" :key="key">
          {{ item }}
        </div>
      </div>
      <!-- <img v-else src="./image/null.png" alt=""> -->
    </div>
    <!-- 海师数据 -->
    <div class="m2">
      <div class="title">
        海师数据
      </div>
      <div class="charts" ref="charts"></div>
    </div>
    <div class="fenxiang">
      <div class="btn" @click="copy()">
        <i class="xhgcopy iconfont"></i>
        复制网址
      </div>
      <span></span>
      <div class="btn" @click="boda()">
        <i class="xhgheadset-one iconfont"></i>
        联系客服
      </div>
    </div>
    <!-- <div class="foot">
      <img src="./image/logo.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Bus } from "@/views/home/components/bus.js";
import { getDetails } from "@/api/haishi";
import VueClipboard from "vue-clipboard2";
export default {
  data() {
    return {
      haishi: {
        ap: [],
        cgkc: [],
        hd_yycj: [],
        list: [],
        school: {},
      },
    };
  },
  created() {
    Bus.$emit("changeHeaderShow", false);//关闭pc的通用底部
    console.log(window.location);
    // 判断移动还是pc
    this.browserRedirect()
  },
  mounted() {
    this.rem();
    window.onresize = () => {
      this.rem();
    };
    this.getDtl();
  },
  methods: {
    rem() {
      // document.documentElement.style.fontSize=document.body.offsetWidth/7.5+"px"
      let designSize = 750; // 设计图尺寸
      let html = document.documentElement;
      let wW = html.clientWidth; // 窗口宽度
      let rem = (wW * 100) / designSize;
      document.documentElement.style.fontSize = rem + "px";
    },
    //获取海师详情信息
    async getDtl() {
      await getDetails({
        hd_id: this.$route.query.id,
      }).then((res) => {
        this.haishi = res.data;
        this.charts();
      });
    },
    //图表
    charts() {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create(this.$refs.charts, am4charts.PieChart);
      // Add data
      chart.radius = am4core.percent(70);
      chart.innerRadius = am4core.percent(40);
      chart.data = this.haishi.list;
      chart.padding(0, 20, 0, 20);
      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.labels.template.text = `[#333333 text-align:center] {value}次[/] \n [#999999 text-align:center]{name}[/]`;
      pieSeries.labels.template.fill = am4core.color("#62472C");
      // This creates initial animation
      // pieSeries.hiddenState.properties.opacity = 1;
      // pieSeries.hiddenState.properties.endAngle = -90;
      // pieSeries.hiddenState.properties.startAngle = -90;
      pieSeries.hiddenState.properties.endAngle = -90;
      chart.hiddenState.properties.radius = am4core.percent(0);
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "right";
      // chart.legend.labels.template.fill = am4core.color("#62472C");
      // chart.legend.valueLabels.template.fill = am4core.color("#62472C");
      pieSeries.colors.list = [
        am4core.color("#F5C98D"),
        am4core.color("#F4C27D"),
        am4core.color("#F3BC70"),
      ];
      // chart.legend.position = "absolute";
      // chart.legend.maxWidth = 50;
      // chart.legend.dx = 170;
      // chart.legend.dy = -120;
      // chart.legend.scrollable = true;
    },
    //复制
    copy() {
      this.$copyText(window.location.href).then((res) => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
      });
    },
    //拨打电话
    boda() {
      window.location.href = "tel:4009009770";
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        // window.location.href = "移动端网站地址";
      } else {
        // window.location = "PC端网站地址";
        this.$router.push(`/haishi/haishiDetails?id=${this.$route.query.id}`);
      }
    },
  },
  destroyed() {
    window.onresize = null;
    document.documentElement.style.fontSize = "";
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-message {
  font-size: 12px;
}
.detail {
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  font-size: 0.28rem;
  padding-bottom: 1.6rem;
  .head_img {
    width: 100%;
    display: block;
  }
  .m1 {
    width: 7.5rem;
    height: 2.39rem;
    background: #ffffff;
    border-radius: 0.2rem 0.2rem 0rem 0rem;
    margin-top: -0.34rem;
    position: relative;
    box-sizing: border-box;
    padding: 0.2rem 0.3rem;
    .t1 {
      text-align: right;
      position: relative;
      img {
        width: 1.5rem;
        height: 1.5rem;
        background: #ededed;
        border: 0.04rem solid #ffffff;
        border-radius: 50%;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
      span {
        background: #f5f5f5;
        border-radius: 0.25rem;
        display: inline-block;
        padding: 0.1rem 0.25rem;
        margin-left: 0.25rem;
        color: #d99d66;
        i {
          margin-right: 0.16rem;
        }
      }
    }
    .t2 {
      margin-top: 0.2rem;
      display: flex;
      align-items: center;
      p {
        margin-right: 0.2rem;
        font-size: 0.4rem;
        font-weight: 500;
        color: #333333;
      }
      span {
        font-size: 0.24rem;
        font-weight: 400;
        margin-right: 0.1rem;
        border-radius: 0.12rem 0.03rem 0.12rem 0.03rem;
        padding: 0.1rem 0.2rem;
      }
      span:nth-of-type(1) {
        color: #935d14;
        background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
      }
      span:nth-of-type(2) {
        color: #ffffff;
        background: linear-gradient(270deg, #f4523b 0%, #ffa598 100%);
      }
    }
    .t3 {
      margin-top: 0.3rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #666666;
    }
  }
  .m2 {
    margin-top: 0.15rem;
    padding: 0 0.3rem;
    background: #ffffff;
    padding-bottom: 0.15rem;
    .title {
      font-size: 0.3rem;
      font-weight: 500;
      color: #333333;
      padding: 0.3rem 0px;
      i {
        color: #cccccc;
        margin-right: 0.15rem;
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;

      .item {
        min-width: 1.3rem;
        text-align: center;
        padding: 0.08rem 0.2rem;
        background: #f5f5f5;
        color: #666666;
        border-radius: 0.08rem;
        margin-bottom: 0.2rem;
        margin-right: 0.2rem;
      }
    }
    .charts {
      width: 100%;
      height: 5rem;
      position: relative;
      &::after {
        width: 100px;
        height: 26px;
        background: white;
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
      }
    }
    > img {
      width: 100%;
    }
  }
  .yycj {
    .items {
      .item {
        color: #d99d66;
        background: rgba(217, 157, 102, 0.2);
      }
    }
  }
  .fenxiang {
    width: 100%;
    height: 1.6rem;
    background: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 0.3rem;
    padding-bottom: 0.6rem;
    position: fixed;
    bottom: 0px;
    z-index: 10;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
    .btn {
      width: 2.45rem;
      height: 0.7rem;
      border-radius: 0.35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      i {
        margin-right: 0.2rem;
      }
    }
    .btn:nth-of-type(1) {
      border: 1px solid #ededed;
    }
    .btn:nth-of-type(2) {
      color: #fa6400;
      background: #f7ebe0;
    }
    span {
      width: 0.01rem;
      height: 0.5rem;
      background: #e3e3e3;
    }
  }
  .foot {
    width: 100%;
    height: 1.78rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 0.7rem;
    }
  }
}
</style>
